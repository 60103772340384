import { useState, useEffect } from "react"
import TilesList from "./components/TilesList"
import Grid from "@mui/material/Grid"
import { fetchLikedTiles } from "./common/FetchClient"
import SearchBar from "./components/SearchBar"
import { LikedMap, Tile } from "./common/Types"


function App() {
  const [likedTiles, setLikedTiles] = useState<LikedMap>({})
  const [searchResults, setSearchResults] = useState([])

  const fetchSetLikedTiles = () => {
    fetchLikedTiles()
      .then((likedTilesList) => {
        return likedTilesList.results.reduce(
          (acc: LikedMap, tile: Tile) => {
            if (tile.liked)
              acc[tile.id] = tile
            else
              delete acc[tile.id]
            return acc
          },
          {}
        )
      }).then((id2LikeMap) => {
        setLikedTiles(id2LikeMap)
      })
  }

  useEffect(() => {
    fetchSetLikedTiles()
  }, [])

  useEffect(() => {
    const intervalId = setInterval(fetchSetLikedTiles, 5000)
    return () => {
      clearInterval(intervalId);
    }
  }, [])

  return (
    <div>
      <Grid container minHeight={'100vh'}>
        <Grid item px={5} py={3} borderRight={1} xs={7} sx={{ overflowY: "scroll", maxHeight: "100vh" }}>
          <SearchBar setSearchResults={setSearchResults} />
          <TilesList showLikeButton tilesList={searchResults} setLikedTiles={setLikedTiles} likedTiles={likedTiles} />
        </Grid>

        <Grid item px={5} xs={5} sx={{ overflowY: "scroll", maxHeight: "100vh" }}>
          <h1
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}>
            My Likes
          </h1>
          <TilesList tilesList={Object.values(likedTiles).sort((a, b) => a.title < b.title ? -1 : 1)} setLikedTiles={setLikedTiles} likedTiles={likedTiles} />
        </Grid>

      </Grid>

    </div>
  )
}

export default App
