import React from 'react'
import List from '@mui/material/List'
import TileItem from './TileItem'
import { LikedMap, Tile } from '../common/Types'

interface TilesListProps {
    setLikedTiles: React.Dispatch<React.SetStateAction<LikedMap>>,
    likedTiles: LikedMap,
    tilesList: Array<Tile>,
    showLikeButton?: boolean
}

function TilesList(props: TilesListProps): JSX.Element {
    return (
        <List>
            {props?.tilesList.map((tile: Tile) => (
                <TileItem
                    showLikeButton={props.showLikeButton}
                    tile={tile}
                    likedTiles={props.likedTiles}
                    setLikedTiles={props.setLikedTiles}
                    key={tile.id}
                />
            ))}
        </List>
    )
}

export default TilesList
