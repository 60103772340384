import React from "react"
import { LazyLoadImage } from "react-lazy-load-image-component"
import Switch from "@mui/material/Switch"
import ListItem from "@mui/material/ListItem"
import { updateTileStatus } from "../common/FetchClient"
import { LikedMap, Tile } from "../common/Types"
import { Box } from "@mui/system"

interface TileItemProps {
    setLikedTiles: React.Dispatch<React.SetStateAction<LikedMap>>
    likedTiles: LikedMap
    tile: Tile
    showLikeButton?: boolean
}

function TileItem(props: TileItemProps): JSX.Element {
    const handleToggle = (id: string, curr_tile_state: Tile) => {
        const updateType = curr_tile_state.liked ? "remove_like" : "add_like"
        updateTileStatus(updateType, id).then(() => {
            props.setLikedTiles((prevLikedTiles: LikedMap) => {
                if (curr_tile_state.liked) {
                    const newLikedTiles = structuredClone(prevLikedTiles)
                    delete newLikedTiles[id]
                    return newLikedTiles
                } else {
                    return { ...prevLikedTiles, [id]: curr_tile_state }
                }
            })
        })
    }

    return (
        <ListItem key={props.tile.id}>
            <LazyLoadImage
                src={props.tile.image1x2_full_url}
                width={100}
                height={200}
                placeholderSrc={props.tile.image1x2_lqip}
            />
            <Box pl={2} alignSelf={"start"} width={'90%'}>
                <h2>{props.tile.title}</h2>
                <p>{props.tile.summary}</p>
            </Box>
            {props?.showLikeButton && (
                <Switch
                    checked={props.tile.id in props.likedTiles}
                    onClick={() =>
                        handleToggle(props.tile.id, {
                            ...props.tile,
                            liked: props.tile.id in props.likedTiles,
                        })
                    }
                />
            )}
        </ListItem>
    )
}

export default TileItem
