const BASE_API_URL = "https://shades.davidxue.me:443"

const fetchSearchResults = async (searchText: string) => {
  const url = `${BASE_API_URL}/tiles/search?search_text=${encodeURIComponent(
    searchText
  )}`

  const response = await fetch(url)
  if (!response.ok) {
    console.log("fetchSearchResults call to backend api failed")
  }
  const data = await response.json()
  return data
}

const fetchLikedTiles = async () => {
  const url = `${BASE_API_URL}/tiles/likes`

  const response = await fetch(url)
  if (!response.ok) {
    console.log("fetchLikedTiles call to backend api failed")
  }
  const data = await response.json()
  return data
}

const updateTileStatus = async (
  updateType: "remove_like" | "add_like",
  id: string
) => {
  const url = `${BASE_API_URL}/tiles/${updateType}`

  const response = await fetch(url, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ id: id }),
  })

  if (!response.ok) {
    console.log("updateTileStatus call to backend api failed")
  }
  const data = await response.json()
  return data
}

export { fetchSearchResults, fetchLikedTiles, updateTileStatus }
