import React, { ChangeEvent, useEffect, useState } from "react"
import { useDebounce } from "use-debounce"
import { fetchSearchResults } from "../common/FetchClient"
import { TextField } from "@mui/material"

interface SearchBarProps {
  setSearchResults: React.Dispatch<React.SetStateAction<never[]>>
}

function TilesList(props: SearchBarProps): JSX.Element {
  const [searchText, setSearchText] = useState("")
  const [query] = useDebounce(searchText, 300)
  const inputHandler = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value)
  }
  const { setSearchResults } = props

  useEffect(() => {
    if (query) {
      fetchSearchResults(query).then((data) => {
        setSearchResults(data.results)
      })
    } else {
      setSearchResults([])
    }
  }, [query, setSearchResults])

  return (
    <TextField
      id="outlined-basic"
      onChange={inputHandler}
      variant="outlined"
      fullWidth
      label="Search"
    />
  )
}

export default TilesList
